/* Additional Autumn Warmth styling */
.bg-gradient-autumn {
  background: linear-gradient(135deg, #2c2b3c 0%, #3d3c53 100%);
}

.text-shadow-autumn {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.transition-autumn {
  transition: all 0.3s ease;
}

/* Hover effects for project cards */
.project-card-hover:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
  border-color: var(--color-accent);
}

/* Subtle animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease forwards;
}

.animate-fadeIn-delay-1 {
  animation: fadeIn 0.5s ease 0.1s forwards;
  opacity: 0;
}

.animate-fadeIn-delay-2 {
  animation: fadeIn 0.5s ease 0.2s forwards;
  opacity: 0;
}
