/* Font Imports */
@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jersey+15&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Terminal Screen Effects */
@layer components {
  .terminal-screen {
    @apply bg-terminal-black text-terminal-green font-vt;
    position: relative;
  }

  .terminal-screen::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      0deg,
      rgba(0, 255, 0, 0.03),
      rgba(0, 255, 0, 0.03) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
    z-index: 2;
  }

  .terminal-screen::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at center,
      transparent 0%,
      rgba(12, 12, 12, 0.3) 80%,
      rgba(12, 12, 12, 0.5) 100%
    );
    pointer-events: none;
    z-index: 3;
  }
}

/* Add some custom utility classes */
@layer utilities {
  .text-shadow-terminal {
    text-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
  }

  .text-shadow-terminal-amber {
    text-shadow: 0 0 5px rgba(255, 191, 0, 0.7);
  }
}

/* third party imports */
/* @import "atropos/css"; */
